import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import CButton from "~/components/CButton/CButton";
export const _frontmatter = {
  "menuLabel": "For Developers",
  "sortOrder": 2,
  "title": "Getting Started for Developers"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Getting Started For Developers`}</h1>
    <p className="p-large">Beginning to use Caesars UI is easy.</p>
    <p>{`Caesars Sportsbook currently supports three platforms, Web, iOS and Android.  Depending on this platform you work in, there will be resources specific to your experience.`}</p>
    <h2>{`Web`}</h2>
    <p>{`Web / Cordova developers will have access to `}<inlineCode parentName="p">{`@caesars-digital/caesars-ui`}</inlineCode>{`, a node package that contains our component library and token output (as CSS Variables and some small CSS classes).  You can use these tokens in your Sass files, CSS Modules or whatever method you're using to create CSS.   Alternatively, you can use Tailwind CSS as well, which should be available inside the `}<inlineCode parentName="p">{`sportsbook-web-nj`}</inlineCode>{` project by default.   This would require no extra effort on your part, simply start including our Tailwind classes in your React components.`}</p>
    <p>{`View the `}<a parentName="p" {...{
        "href": "/getting-started/getting-started-for-developers/quick-start"
      }}>{`Quick Start Guide`}</a>{` for info on getting up and running.`}</p>
    <hr></hr>
    <h2>{`iOS`}</h2>
    <p>{`iOS Engineers will be able to access Caesars UI design system assets, components and tokens via our Swift Package. This Swift Package has been developed by key contributors from our iOS Native teams as a collaborative effort with the design system team to provide an easy to access and update Swift Package.  `}</p>
    <p>{`To install the Swift Package, point to our Gitlab repository `}<inlineCode parentName="p">{`caesars-ui-ios`}</inlineCode>{`. More documentation coming soon upon official release.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      